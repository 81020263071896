import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_ENDPOINT
const claimSite = process.env.VUE_APP_CLAIM_V2

let partnerProgramId: number

axios.interceptors.request.use(
  function (config) {
    const updatedConfig = { ...config }

    // Attach program id on all request
    if (config.method != 'get' && config.method != 'GET') {
      updatedConfig.data = {
        ...updatedConfig.data,
        partner_program_id: partnerProgramId,
      }
    } else {
      updatedConfig.params = {
        ...updatedConfig.params,
        partner_program_id: partnerProgramId,
      }
    }

    // Attach authorization header
    const token = localStorage.getItem('token')
    if (!token) return updatedConfig
    return {
      ...updatedConfig,
      headers: {
        ...updatedConfig.headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

export const setPartnerProgram = (programId: number): void => {
  partnerProgramId = programId
}

// all api endpoint paths
export const LOGIN = `${apiUrl}/partner-portal/login/`
export const LIST_PROPERTIES = `${apiUrl}/partners/partner-properties-permissions/`
export const GET_DISCOUNT_CODE = `${apiUrl}/v1/discounts/check/`
export const REGISTER = `${apiUrl}/partner-portal/registrations/`
export const GET_COVERAGES = `${apiUrl}/coverages/v2/`
export const GET_PHONE_MODELS_AND_RELATED_COVERAGES = `${apiUrl}/phone_models/v2/`
export const SIGNUP = `${apiUrl}/partners/signup/`
export const SIGNUP_AND_CLAIM_USER = `${apiUrl}/partner-portal/user/signup-and-claim-partner/`
export const ADD_STORES = `${apiUrl}/partners/add-stores/`
export const ADD_PAYPAL_EMAIL = `${apiUrl}/partners/add-paypal-email/`
export const VALIDATE_USER = `${apiUrl}/partners/validate-user/`
export const GET_SALES_TAX = `${apiUrl}/users/tax-rate/`

// sales
export const REPORT_SALES = `${apiUrl}/partners/reports/sales/`
export const SALES_OVERVIEW = `${apiUrl}/api/v2/partner-properties/sales-overview/`

// Partner Properties
export const GET_USER_WITH_ACCESS_TO_PARTNER_PROPERTY = `${apiUrl}/partner-properties/user-with-permissions/`
export const GET_PARTNER_PROPERTIES_WITH_SALES_PERMISSIONS = `${apiUrl}/api/v2/partner-properties/register-sales/`
export const GET_REPAIR_DEVICES = `${apiUrl}/partner-properties/repair-devices/`
export const GET_SELL_DEVICES = `${apiUrl}/partner-properties/sell-devices`
export const GET_CELLULAR_SERVICES = `${apiUrl}/partner-properties/cellular-services`

// Partner Portal User
export const CREATE_PARTNER_PORTAL_USER = `${apiUrl}/partner-portal/user/`
export const GET_PARTNER_USERS = `${apiUrl}/partner-portal/user/`
export const GET_PARTNER_PROPERTIES_WITH_ACCESS = `${apiUrl}/partner-portal/user/partner-properties/`
export const PARTNER_PROPERTY = `${apiUrl}/api/v2/partner-properties/`
export const RESET_PASSWORD = `${apiUrl}/partner-portal/user/reset-password/`
export const GET_CURRENT_USER = `${apiUrl}/partner-portal/user/current-user/`
export const UPDATE_PASSWORD = `${apiUrl}/partner-portal/user/update-password/`
export const UPDATE_USER = `${apiUrl}/partner-portal/user/update-user/`
export const ACCEPT_TERMS_AND_CONDITIONS = `${apiUrl}/partner-portal/user/accept-terms-and-conditions/`

// Partner
export const PARTNER = `${apiUrl}/api/v2/partners/`
export const ABOUT_AKKO = `${apiUrl}/api/v2/partners/about/`
export const COUNTRY = `${apiUrl}/api/v2/partners/country/`
export const PARTNER_TYPE = `${apiUrl}/api/v2/partners/partner-type/`
export const PARTNER_PROPERTY_TYPE = `${apiUrl}/api/v2/partners/partner-property-type/`
export const PARTNER_STRATEGY_UI_SETTINGS = `${apiUrl}/partner-strategies/partner/{1}/ui-settings/`

// Team users
export const TEAM_USERS = `${apiUrl}/api/v2/partner-portal/teams/`

// Claims
export const CLAIMS_LOOKUP = `${apiUrl}/claims/lookup`
export const CLAIMS_GET_REPLACEMENT_COST = `${apiUrl}/cost-provider/c/replacement-cost/{0}/`
export const CLAIMS_GET_DEDUCTIBLES = `${apiUrl}/c/claims/{0}/devices/{1}/deductibles`

// Compensation
export const COMPENSATION_PAYOUT_AMOUNT = `${apiUrl}/partners/compensations/`
export const COMPENSATION_SINGLE_TIME_PAYOUTS = `${apiUrl}/api/v2/partners/payouts/{0}/single-time-payouts/`

// End users (Customers)
export const END_USERS = `${apiUrl}/partners/end-users`
export const DELETE_END_USERS = `${apiUrl}/api/v2/partners/{0}/users/{1}/`
export const AUTH_CLAIM_AS_USER = `${apiUrl}/api/v2/partners/users/auth/`

// Feature Flags
export const GET_FEATURE_FLAGS = `${apiUrl}/feature_flags/`

// Products
export const GET_PARTNER_CATALOGS = `${apiUrl}/api/v2/partners/{0}/catalogs/`
export const GET_CATALOG_PRODUCTS = `${apiUrl}/api/v2/partners/{0}/catalogs/{1}/products/`
export const GET_INTERVALS = `${apiUrl}/intervals/`

export const CLAIM_V2_SITE = `${claimSite}`
