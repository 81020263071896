export const RouteNames = {
  HOME: 'Home',
  LOGIN: 'Login',
  RESET_PASSWORD: 'ResetPassword',
  SIGNUP: 'Signup',
  SIGNUP_REFERRAL_CODE: 'Signup_Referral_Code',
  SIGNUP_AND_CLAIM_PARTNER: 'Signup_And_Claim_Partner',
  ADD_PROPERTIES: 'AddProperties',
  PAYPAL_EMAIL: 'PaypalEmail',
  HOME_MENU: 'Home_Menu',
  HOME_CLAIMS: 'Home_Claims',
  HOME_CLAIMS_STATUS: 'Home_Claims_Status',
  HOME_CLAIMS_STATUS_BY_DEVICE: 'Home_Claims_Status_By_Device',
  HOME_CLAIMS_DEVICE_INFO: 'Home_Claims_Device_Info',
  HOME_SALES: 'Home_Sales',
  HOME_SALES_REPORT: 'Home_Sales_Report',
  HOME_SALES_TEAM: 'Home_Sales_Team',
  HOME_ACCOUNT: 'Home_Account',
  HOME_PROPERTIES: 'Home_Properties',
  HOME_REGISTER_NEW_PLAN: 'Home_Register_New_Plan',
  HOME_REPRESENTATIVES: 'Home_Team',
  HOME_MARKETING_MATERIALS: 'Home_Marketing_Materials',
  HOME_CHECKOUT: 'Home_Checkout',
  HOME_END_USERS: 'Home_End_Users',
  HOME_PAYOUT_DETAIL: 'Home_Payout_Detail',
  HOME_REGISTER_NEW_PLAN_INDIVIDUAL: 'Home_Register_New_Plan_Individual',
  HOME_REGISTER_NEW_FLAT_RATE_PLAN: 'Home_Register_New_Flat_Rate_Plan',
  HOME_REGISTER_NEW_PLAN_FAMILY: 'Home_Register_New_Plan_Family',
  HOME_REGISTER_NEW_PLAN_BUSINESS: 'Home_Register_New_Plan_Business',
  HOME_REGISTER_NEW_PLAN_WARRANTY: 'Home_Register_New_Plan_Warranty',
  HOME_REGISTER_NEW_PLAN_WARRANTY_FOR_FIG:
    'Home_Register_New_Plan_Warranty_For_Fig',
}
export const RoutePath = {
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  SIGNUP_THROUGH_DISTRIBUTOR: '/signup/:distributorSlug',
  SIGNUP_REFERRAL_CODE: '/signup-referral-code',
  SIGNUP_AND_CLAIM_PARTNER: '/signup-and-claim-partner',
  RESET_PASSWORD: '/reset-password',
  ADD_PROPERTIES: '/add-properties',
  PAYPAL_EMAIL: '/add-paypal-email',
  HOME_CLAIMS: '/claims',
  HOME_CLAIMS_STATUS: '/claims/status',
  HOME_CLAIMS_STATUS_BY_DEVICE: '/claims/status/device/:id',
  HOME_CLAIMS_DEVICE_INFO: '/claims/device-info',
  HOME_SALES: '/sales',
  HOME_SALES_REPORT: '/sales/report',
  HOME_SALES_TEAM: '/sales/team',
  HOME_ACCOUNT: '/account',
  HOME_REGISTER_NEW_PLAN: '/register-new-plan',
  HOME_REPRESENTATIVES: '/team',
  HOME_PROPERTIES: '/properties',
  HOME_MARKETING_MATERIALS: '/marketing-materials',
  HOME_CHECKOUT: '/checkout',
  HOME_END_USERS: '/customers',
  HOME_PAYOUT_DETAIL: '/payout-details',
  HOME_REGISTER_NEW_PLAN_INDIVIDUAL: '/register-new-plan/individual-plan',
  HOME_REGISTER_NEW_FLAT_RATE_PLAN: '/register-new-plan/flat-rate-plan',
  HOME_REGISTER_NEW_PLAN_FAMILY: '/register-new-plan/family-plan',
  HOME_REGISTER_NEW_PLAN_BUSINESS: '/register-new-plan/business-plan',
  HOME_REGISTER_NEW_PLAN_WARRANTY: '/register-new-plan/activate-warranty',
  HOME_REGISTER_NEW_PLAN_WARRANTY_FOR_FIG:
    '/register-new-plan/activate-warranty-for-fig',
}
