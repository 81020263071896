
import { defineComponent, reactive, ref } from 'vue'
import isEmpty from 'lodash/isEmpty'
import Modal from '@/components/common/Modal.vue'
import Checkout from '@/components/registration/Checkout.vue'
import { PlanIntervals, CoverageType } from '@/constants/coverages'
import {
  CoverageSelection,
  CoverageSelectionMap,
} from '@/types/plan-registration'
import { DiscountCode } from '@/types/discount-code'
import { ModulesIdentifiers, key } from '@/store'
import { PlanRegistrationGetters } from '@/store/modules/plan-registration/getters'
import RegistrationSummary from '@/components/registration/RegistrationSummary.vue'
import ForceToCreatePartnerProperties from '@/components/common/ForceToCreatePartnerProperties.vue'
import { useStore } from 'vuex'
import { UserGetters } from '@/store/modules/user/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { UserActions } from '@/store/modules/user/actions'
import { PartnerState } from '@/types/partner'
import { PartnerGetters } from '@/store/modules/partner/getters'
import RequireToAcceptTerms from '@/components/registration/RequireToAcceptTerms.vue'
import {
  PartnerProgramsIds,
  PartnerPrograms,
} from '@/constants/partner-programs'
import { AppGetters } from '@/store/modules/app/getters'
import { AppMutations } from '@/store/modules/app/mutations'
import { v4 as uuidv4 } from 'uuid'
import { UserState } from '@/types/user'
import { loadCoveragesAndDevices } from '@/composables/coverage'
import CoveragesCombo from '@/composables/coverages-combo'
import { RoutePath, RouteNames } from '@/constants/router'
import { FeatureFlagActions } from '@/store/modules/feature-flag/actions'
import { FeatureFlagGetters } from '@/store/modules/feature-flag/getters'
import { FeatureFlagOptions } from '@/constants/feature-flag'
import { loadProducts } from '@/composables/products'

export default defineComponent({
  name: 'RegisterNewPlan',
  components: {
    RequireToAcceptTerms,
    Checkout,
    Modal,
    RegistrationSummary,
    ForceToCreatePartnerProperties,
  },
  setup() {
    const store = useStore(key)
    const interval = ref(PlanIntervals.MONTHLY)
    const goToCheckout = ref(false)

    store.watch(
      (store) => store.planRegistration.registrationSummary,
      (value, oldValue) => {
        if (!oldValue && value) {
          goToCheckout.value = false
        }
      },
    )
    return {
      PlanIntervals,
      goToCheckout,
      interval,
      PartnerProgramsIds,
      RoutePath,
      RouteNames,
      PartnerPrograms,
    }
  },
  data() {
    return {
      emptyProperty: false,
      coverageCombo: {},
      coverageTypeSelected: '',
      hasLoaded: false,
    }
  },
  async mounted() {
    const operationId = uuidv4()
    this.$store.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_LOADING_OPERATION}`,
      { id: operationId },
    )
    try {
      const selectionMap = reactive<CoverageSelectionMap>({
        indexer: 0,
        selections: {},
      })
      await loadCoveragesAndDevices(this.$store)
      await loadProducts(this.$store)
      this.coverageCombo = new CoveragesCombo(selectionMap, this.$store)

      await Promise.all([
        this.$store.dispatch(
          `${ModulesIdentifiers.USER}/${UserActions.GET_PARTNER_PROPERTIES_WITH_ACCESS}`,
          {
            scope: PermissionScopes.REGISTER_PLAN,
            value: PermissionValues.ALLOW,
          },
        ),
        this.$store.dispatch(
          `${ModulesIdentifiers.USER}/${UserActions.GET_CURRENT_USER_INFORMATION}`,
        ),
      ])
      this.$store.commit(
        `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
        { id: operationId },
      )
    } catch (e) {
      this.$store.commit(
        `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
        { id: operationId },
      )
    }

    const partnerProperties = this.$store.getters[
      `${ModulesIdentifiers.USER}/${UserGetters.PARTNER_PROPERTIES_WITH_ACCESS}`
    ](`${PermissionScopes.REGISTER_PLAN}:${PermissionValues.ALLOW}`)
    this.emptyProperty = isEmpty(partnerProperties)

    await this.$store.dispatch(
      `${ModulesIdentifiers.FEATURE_FLAG}/${FeatureFlagActions.GET_FEATURE_FLAGS_ACCESS}`,
    )
    this.hasLoaded = true
    if (
      this.hasPartnerSalesRegisterPlanAkkoPlansPermission ||
      this.hasPartnerSalesRegisterPlanPhonePlansPermission
    ) {
      this.$router.push({ name: RouteNames.HOME_REGISTER_NEW_PLAN_INDIVIDUAL })
    } else if (this.hasPartnerSalesRegisterPlanHomePlansPermission) {
      this.$router.push({ name: RouteNames.HOME_REGISTER_NEW_PLAN_FAMILY })
    } else if (this.hasPartnerSalesRegisterPlanBusinessPlansPermission) {
      this.$router.push({ name: RouteNames.HOME_REGISTER_NEW_PLAN_BUSINESS })
    } else if (this.hasPartnerSalesRegisterPlanActivateWarrantyPermission) {
      this.$router.push({ name: RouteNames.HOME_REGISTER_NEW_PLAN_WARRANTY })
    }
  },
  methods: {
    setPlanInterval(newInterval: PlanIntervals): void {
      this.interval = newInterval
    },
    triggerRegistering(): void {
      this.coverageTypeSelected = ''
      this.goToCheckout = true
    },
    cancelRegistration(): void {
      this.goToCheckout = false
    },
    intervalSelected(interval: PlanIntervals): boolean {
      return interval == this.interval
    },
    addBusinessPlan(type: CoverageType) {
      this.coverageTypeSelected = type
      this.goToCheckout = true
    },
    isActive(path: string): boolean {
      return this.$router.currentRoute.value.name === path
    },
  },
  computed: {
    coverageSelection(): {
      plans: CoverageSelection[]
      discounts: DiscountCode[]
    } {
      return this.$store.state.planRegistration
    },
    registrationSummary(): {
      phoneNumber: string[]
      transactionId: string
    } | null {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.REGISTRATION_SUMMARY}`
      ]
    },

    partnerInformation(): PartnerState {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    partnerProgramId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM_ID}`
      ]
    },
    currentUser(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    forceToCreatePartnerProperties(): boolean {
      return (
        this.emptyProperty &&
        (this.currentUser.termsAccepted ||
          this.partnerProgramId === PartnerProgramsIds.INJURED_GADGETS_ID)
      )
    },
    requireToAcceptTerms(): boolean {
      return (
        !this.currentUser.termsAccepted &&
        this.partnerProgramId !== PartnerProgramsIds.INJURED_GADGETS_ID
      )
    },
    isEnableHomePlanFeature(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.FEATURE_FLAG}/${FeatureFlagGetters.CHECK_FEATURE_FLAG_ENABLED}`
      ](FeatureFlagOptions.PARTNER_PORTAL_HOME_PLAN)
    },
    hasPartnerSalesRegisterPlanHomePlansPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_SALES_REGISTER_PLAN_HOME_PLANS,
        PermissionValues.ALLOW,
      )
    },
    isEnableActivateWarrantyFeature(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.FEATURE_FLAG}/${FeatureFlagGetters.CHECK_FEATURE_FLAG_ENABLED}`
      ](FeatureFlagOptions.PARTNER_PORTAL_ACTIVATE_WARRANTY)
    },
    hasPartnerSalesRegisterPlanActivateWarrantyPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_SALES_REGISTER_PLAN_ACTIVATE_WARRANTY,
        PermissionValues.ALLOW,
      )
    },
    hasUserPartnerPortalAdminUsersPermissionForViewOrEdit(): string {
      return (
        this.$store.getters[
          `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
        ](PermissionScopes.ADMIN_USERS, PermissionValues.VIEW) ||
        this.$store.getters[
          `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
        ](PermissionScopes.ADMIN_USERS, PermissionValues.EDIT)
      )
    },
    hasPartnerSalesRegisterPlanAkkoPlansPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_SALES_REGISTER_PLAN_AKKO_PLANS,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerFlatRatePermission(): boolean {
      const allow_register_catalog_flat_rate_plan = this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_SALES_CATALOG_FLAT_RATE_PLAN,
        PermissionValues.ALLOW,
      )

      const allow_partner_to_catalogs = this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_SALES_CATALOG, PermissionValues.ALLOW)

      const are_catalogs_active = this.$store.getters[
        `${ModulesIdentifiers.FEATURE_FLAG}/${FeatureFlagGetters.CHECK_FEATURE_FLAG_ENABLED}`
      ](FeatureFlagOptions.PARTNER_CATALOGS)

      return (
        allow_register_catalog_flat_rate_plan &&
        allow_partner_to_catalogs &&
        are_catalogs_active
      )
    },
    hasPartnerSalesRegisterPlanPhonePlansPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_SALES_REGISTER_PLAN_PHONE_PLANS,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerSalesRegisterPlanBusinessPlansPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_SALES_REGISTER_PLAN_BUSINESS_PLANS,
        PermissionValues.ALLOW,
      )
    },
    isEnableExtendedWarrantyForFigFeature(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.FEATURE_FLAG}/${FeatureFlagGetters.CHECK_FEATURE_FLAG_ENABLED}`
      ](FeatureFlagOptions.PARTNER_PORTAL_EXTENDED_WARRANTY_FOR_FIG)
    },
    hasPartnerSalesRegisterPlanActivateWarrantyForFigPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_SALES_REGISTER_PLAN_EXTENDED_WARRANTY_FOR_FIG,
        PermissionValues.ALLOW,
      )
    },
  },
})
