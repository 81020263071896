
import { defineComponent } from 'vue'
import RegisterNewPlanIcon from '@/components/common/icons/RegisterNewPLan-Icon.vue'
import ContactSupportIcon from '@/components/common/icons/ContactSupport-Icon.vue'
import ChevronRightIcon from '@/components/common/icons/ChevronRight-Icon.vue'
import { RoutePath } from '@/constants/router'
import { showChat } from '@/services/intercom'
import { ModulesIdentifiers } from '@/store'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { UserGetters } from '@/store/modules/user/getters'

export default defineComponent({
  name: 'QuickActions',
  components: {
    ContactSupportIcon,
    RegisterNewPlanIcon,
    ChevronRightIcon,
  },
  setup() {
    return {
      RoutePath,
    }
  },
  methods: {
    openIntercom() {
      showChat()
    },
  },
  computed: {
    hasPartnerRegisterNewPlanViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_REGISTER_NEW_PLAN_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerFaqsInfoViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_FAQS_INFO_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerPortalRegisterNewPlanViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_REGISTER_NEW_PLAN_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalFaqsInfoViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_FAQS_INFO_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
  },
})
