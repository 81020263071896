
import { defineComponent } from 'vue'
import { MARKETING_EMAIL } from '@/constants/marketing-materials'

export default defineComponent({
  name: 'MarketingMaterials',
  setup() {
    return {
      MARKETING_EMAIL,
    }
  },
})
