
import { defineComponent, PropType } from 'vue'
import { ProductGroupMarketingAttributes, ProductInCart } from '@/types/product'
import { PlanIntervals } from '@/constants/coverages'
import { ModulesIdentifiers } from '@/store'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { ProductGetters } from '@/store/modules/products/getters'

export default defineComponent({
  name: 'ChosenProductInfo',
  props: {
    product: {
      type: Object as PropType<ProductInCart>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    productGroupName: {
      type: String,
      required: true,
    },
  },
  computed: {
    productTitle() {
      return this.product.name
    },
    productPrice() {
      const price = this.product.prices.find(
        (p) =>
          p.interval.toLowerCase() ===
          this.$store.state.products.productCartInterval.toLowerCase(),
      )
      if (!price) return ''
      return `$${(price.currentPrice / 100).toFixed(2)} / ${
        price.interval.toLowerCase() === PlanIntervals.ANNUAL.toLowerCase()
          ? 'year'
          : 'month'
      }`
    },
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
    deductiblesOverwrites() {
      const marketingAttributes = this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCT_GROUP_MARKETING_ATTRIBUTES_FROM_PRODUCT_GROUP_NAME}`
      ](this.productGroupName) as ProductGroupMarketingAttributes
      return marketingAttributes.deductibles
    },
    displayedDeductiblesOverwrites() {
      const marketingAttributes = this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCT_GROUP_MARKETING_ATTRIBUTES_FROM_PRODUCT_GROUP_NAME}`
      ](this.productGroupName) as ProductGroupMarketingAttributes

      const displayedDeductibles = marketingAttributes.displayedDeductibles

      if (!displayedDeductibles) return []

      const result = []
      for (const deductibleGroupKey in displayedDeductibles) {
        result.push({
          deductibles: displayedDeductibles[deductibleGroupKey],
          key: deductibleGroupKey,
          num: Object.keys(displayedDeductibles[deductibleGroupKey]).length,
        })
      }
      debugger
      console.log('res:', result)
      return result.sort((groupA, groupB) => {
        if (groupA.key == 'All Other Claims') {
          return 1
        }

        if (groupB.key == 'All Other Claims') {
          return -1
        }

        if (groupA.num > groupB.num) {
          return -1
        }
        if (groupB.num > groupA.num) {
          return 1
        }
        return 0
      })
    },
  },
})
