
import isEmpty from 'lodash/isEmpty'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { Report } from '@/composables/reports'
import { key, ModulesIdentifiers } from '@/store'
import { ReportGetters } from '@/store/modules/report/getters'
import { SalesGetters } from '@/store/modules/sales/getters'
import { TeamGetters } from '@/store/modules/team/getters'
import { FilterReport } from '@/types/report'
import { SalesRangeReportTeam, SalesUserInformation } from '@/types/sales'
import LastTwelveMonthsSelector from '@/components/common/LastTwelveMonthsSelector.vue'
import AkkoCard from '@/components/common/AkkoCard.vue'
import TeamRow from '@/components/sales/TeamRow.vue'
import { ReportMutations } from '@/store/modules/report/mutations'
import { SalesMutations } from '@/store/modules/sales/mutations'
import { RouteNames } from '@/constants/router'

export default defineComponent({
  name: 'Team',
  components: {
    AkkoCard,
    LastTwelveMonthsSelector,
    TeamRow,
  },
  data() {
    return {
      loading: true as boolean,
      monthSelection: null as unknown,
      salesRepresentatives: [] as SalesUserInformation[],
    }
  },
  setup() {
    const store = useStore(key)
    const report: Report = new Report(store)

    return {
      report,
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(this.onChangeReportByTeam)
  },
  mounted() {
    this.setTabTeam()
    this.report.dispatchRequestReportSalesByTeam(this.getFilterParams())
  },
  unmounted() {
    this.unsubscribe()
  },
  computed: {
    partnerUsers(): SalesUserInformation[] {
      return this.$store.getters[
        `${ModulesIdentifiers.TEAM}/${TeamGetters.USERS}`
      ]
    },
    filters(): FilterReport {
      const filters =
        this.$store.getters[
          `${ModulesIdentifiers.REPORT}/${ReportGetters.GET_FILTER}`
        ]
      return filters
    },
    getReportsByTeam(): SalesRangeReportTeam {
      const key = `${ModulesIdentifiers.SALES}/${SalesGetters.GET_CUSTOM_MONTH_BY_TEAM}`
      return this.$store.getters[key].data
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    unsubscribe: () => {},
    async handleChangeOnMonthSelection(param: { month: number; year: number }) {
      this.monthSelection = param
      const filterParams = this.getFilterParams()
      const params = {
        ...filterParams,
        month: param.month,
        year: param.year,
      }
      this.report.dispatchRequestReportSalesByTeam(params)
    },
    getFilterParams() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const params: any = {}
      const { partnerProperties, salesRepresentatives, tabSelected } =
        this.filters
      if (!isEmpty(partnerProperties)) {
        params['partner_properties'] = partnerProperties
          .map((v: any) => v.value)
          .join(',')
      }
      if (!isEmpty(salesRepresentatives)) {
        params['sales_representatives'] = salesRepresentatives
          .map((v: any) => v.value)
          .join(',')
      }
      if (tabSelected) {
        params['tab_selected'] = tabSelected
      }
      return params
    },
    setTabTeam(): void {
      this.$store.commit(
        `${ModulesIdentifiers.REPORT}/${ReportMutations.SET_FILTER}`,
        { tabSelected: RouteNames.HOME_SALES_TEAM },
      )
    },
    integrateReportsByTeamWithPartnerUsers(): void {
      if (!isEmpty(this.partnerUsers)) {
        this.salesRepresentatives = this.partnerUsers.map((user) => {
          if (this.getReportsByTeam[`${user.id}`]) {
            const salesRepresentative = {
              ...user,
              sold_phone_plans: this.getReportsByTeam[`${user.id}`].phone,
              sold_akko_plans: this.getReportsByTeam[`${user.id}`].akko,
            }
            return salesRepresentative
          } else {
            const salesRepresentative = {
              ...user,
              sold_phone_plans: 0,
              sold_akko_plans: 0,
            }
            return salesRepresentative
          }
        })
        this.filterSalesRepresentatives()
      }
    },
    filterSalesRepresentatives(): void {
      const { salesRepresentatives } = this.filters
      if (!isEmpty(salesRepresentatives)) {
        this.salesRepresentatives = this.salesRepresentatives.filter(
          (salesRep) => {
            return (
              salesRepresentatives.findIndex(
                (salesRepValue) => salesRepValue.value == salesRep.id,
              ) >= 0
            )
          },
        )
      }
    },
    onChangeReportByTeam({ type }: { type: string }): void {
      const customMonthByTeam = `${ModulesIdentifiers.SALES}/${SalesMutations.SET_SALES_FILTER_TO_MONTH_BY_TEAM}`
      if (type === customMonthByTeam) {
        this.integrateReportsByTeamWithPartnerUsers()
      }
    },
  },
})
