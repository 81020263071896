import { Interval, Product, ProductState } from '@/types/product'
import _ from 'lodash'
import {
  DEVICE_MODEL_SELECTION_REQUIRED,
  IS_PLAN_CONTAINER,
  MARKETING_PROPERTIES,
  PLAN_CATEGORY,
} from '@/constants/products'
import { PlanIntervals } from '@/constants/coverages'
import { v4 as uuidv4 } from 'uuid'

export enum ProductsMutations {
  SET_CATALOGS = 'setCatalogs',
  SET_PRODUCTS = 'setProducts',
  ADD_PRODUCTS_TO_PRODUCT_GROUPS = 'addProductToProductGroups',
  SET_INTERVALS_MONTHLY_CONVERSION_RATIOS = 'setIntervalsMonthlyConversionRatios',
  ADD_PRODUCT_TO_CART = 'addProductToCart',
  ADD_CURRENT_PRODUCT_TO_CART = 'addCurrentProductToCart',
  DEFINE_PRODUCT_ON_CART = 'defineProductOnCart',
  DEFINE_PRODUCT_ON_CART_FROM_MODEL_AND_MAKE = 'defineProductOnCartFromModelAndMake',
  SET_INTERVAL = 'setInterval',
  SET_PRODUCT_IN_CART_PROPERTY = 'setProductInCartProperty',
  SET_PRODUCT_IN_CART_BOOLEAN_UNIQUE_PROPERTY = 'setProductInCartBooleanUniqueProperty',
  REMOVE_PRODUCT_FROM_CART = 'removeProductFromCart',
  REMOVE_NULL_PRODUCT_FROM_CART = 'removeNullProductFromCart',
  RESET_PRODUCT_CATALOG = 'resetProductCatalog',
  RESET_PRODUCTS_CART = 'resetProductCart',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mutations: any = {}

mutations[ProductsMutations.SET_CATALOGS] = function (
  state: ProductState,
  catalogIds: Array<{ id: string }>,
): void {
  state.catalogs = catalogIds.map((c) => c.id)
}

mutations[ProductsMutations.SET_PRODUCTS] = function (
  state: ProductState,
  productCollection: Array<any>,
): void {
  let planContainersGroups: any[] = []

  for (const product of productCollection) {
    if (product.groups && product.groups.length > 0) {
      const planContainers = _.uniqBy(
        product.groups.filter((group: any) =>
          group.attributes.find(
            (attr: any) => attr.name == IS_PLAN_CONTAINER && attr.boolean_value,
          ),
        ),
        'id',
      )
      planContainersGroups = planContainersGroups.concat(planContainers)
    }
  }
  planContainersGroups = _.uniqBy(planContainersGroups, 'id')
  planContainersGroups.forEach((productGroup: any) => {
    const marketingAttributesProp = productGroup.attributes.find(
      (attr: any) => attr.name == MARKETING_PROPERTIES,
    )
    const marketingAttributes = marketingAttributesProp
      ? {
          groupName: marketingAttributesProp.json_value.group_name,
          icon: marketingAttributesProp.json_value.icon
            ? marketingAttributesProp.json_value.icon
            : '',
          caption: marketingAttributesProp.json_value.caption,
          included: {
            description:
              marketingAttributesProp.json_value.included.description,
            details: marketingAttributesProp.json_value.included.details,
          },
          excluded: {
            description:
              marketingAttributesProp.json_value.excluded.description,
            details: marketingAttributesProp.json_value.excluded.details,
          },
          deductibles: marketingAttributesProp.json_value.deductibles
            ? marketingAttributesProp.json_value.deductibles
            : undefined,
          displayedDeductibles: marketingAttributesProp.json_value
            .displayed_deductibles
            ? marketingAttributesProp.json_value.displayed_deductibles
            : undefined,
        }
      : null

    const planCategoryAttribute = productGroup.attributes.find(
      (a: any) => a.name == PLAN_CATEGORY && a.string_value,
    )
    const planCategory = planCategoryAttribute?.string_value || ''

    let isDeviceModelSelectionRequired = false
    const deviceModelSelectionAttribute = productGroup.attributes.find(
      (a: any) => a.name == DEVICE_MODEL_SELECTION_REQUIRED && a.boolean_value,
    )

    if (deviceModelSelectionAttribute) {
      isDeviceModelSelectionRequired = <boolean>(
        deviceModelSelectionAttribute.boolean_value
      )
    }

    state.products.push({
      isDeviceModelSelectionRequired,
      groupName: productGroup.name,
      marketingAttributes: marketingAttributes,
      planCategory,
      products: [],
    })
    state.products = _.uniqBy(state.products, 'marketingAttributes.groupName')

    // This sorting is a HOTFIX that should be replaced by proper sorting backend feature
    state.products = state.products.sort((productA, productB) => {
      if (productA.groupName == 'AKKO Screen') {
        return -1
      }
      if (productA.groupName == 'AKKO Full') {
        return 1
      }
      if (productB.groupName == 'AKKO Screen') {
        return 1
      }
      if (productB.groupName == 'AKKO Full') {
        return -1
      }
      return 0
    })
  })
}

mutations[ProductsMutations.ADD_PRODUCTS_TO_PRODUCT_GROUPS] = function (
  state: ProductState,
  productCollection: Array<any>,
): void {
  for (const product of productCollection) {
    state.products.forEach((productsGroup) => {
      if (
        product.groups.find(
          (group: any) => group.name === productsGroup.groupName,
        )
      ) {
        productsGroup.products.push({
          friendlyId: product.friendly_id,
          id: product.id,
          name: product.name,
          prices: product.prices.map((price: any) => ({
            currentPrice: price.current_price,
            id: price.id,
            interval: price.interval,
            name: price.name,
            retailPrice: price.retail_price,
          })),
          deductibles: {
            currency_id: product.deductibles.currency_id,
            deductible: product.deductibles.deductible,
            id: product.deductibles.id,
            repairDeductible: product.deductibles.repair_deductible,
            replacementDeductible: product.deductibles.replacement_deductible,
            screenRepairDeductible:
              product.deductibles.screen_repair_deductible,
          },
          deviceModels: product.device_models.map((deviceModel: any) => ({
            id: deviceModel.id,
            deviceType: deviceModel.device_type,
            displayOrder: deviceModel.display_order,
            make: deviceModel.make,
            model: deviceModel.model,
            slug: deviceModel.slug,
          })),
        })
        productsGroup.products = _.uniqBy(productsGroup.products, 'id')
      }
    })
  }
}

mutations[ProductsMutations.SET_INTERVALS_MONTHLY_CONVERSION_RATIOS] =
  function (state: ProductState, intervals: Interval[]): void {
    const ratioHolder: any = {}
    for (const interval of intervals) {
      ratioHolder[interval.name] = 1 / interval.number_of_months
    }
    state.intervalMonthlyTransformationRatios = ratioHolder
  }

mutations[ProductsMutations.ADD_PRODUCT_TO_CART] = function (
  state: ProductState,
  productGroupName: string,
): void {
  if (state.productCart[productGroupName]) {
    state.productCart[productGroupName].push(null)
  } else {
    state.productCart[productGroupName] = [null]
  }
  state.currentProductGroupSelection = productGroupName
}

mutations[ProductsMutations.ADD_CURRENT_PRODUCT_TO_CART] = function (
  state: ProductState,
): void {
  if (state.productCart[state.currentProductGroupSelection]) {
    state.productCart[state.currentProductGroupSelection].push(null)
  } else {
    state.productCart[state.currentProductGroupSelection] = [null]
  }
}

mutations[ProductsMutations.DEFINE_PRODUCT_ON_CART] = function (
  state: ProductState,
  payload: { productGroupName: string; index: number; product: Product },
) {
  state.productCart[payload.productGroupName][payload.index] = {
    ...payload.product,
    phoneNumber: '',
    make: null,
    model: null,
    primary: payload.index === 0,
    uiId: uuidv4(),
  }
}

mutations[ProductsMutations.DEFINE_PRODUCT_ON_CART_FROM_MODEL_AND_MAKE] =
  function (
    state: ProductState,
    payload: {
      productGroupName: string
      index: number
      make: string
      model: string
    },
  ) {
    const productGroup = state.products.find(
      (pg) => pg.groupName === payload.productGroupName,
    )

    if (!productGroup) return

    const product = productGroup?.products.find((p) => {
      return p.deviceModels.find((d) => {
        return d.model === payload.model && d.make === payload.make
      })
    })

    if (!product) return

    state.productCart[payload.productGroupName][payload.index] = {
      ...product,
      phoneNumber: '',
      make: payload.make,
      model: payload.model,
      primary: payload.index === 0,
      uiId: uuidv4(),
    }
  }

mutations[ProductsMutations.SET_INTERVAL] = function (
  state: ProductState,
  interval: PlanIntervals,
) {
  state.productCartInterval = interval
}

mutations[ProductsMutations.SET_PRODUCT_IN_CART_PROPERTY] = function (
  state: ProductState,
  payload: {
    change: { name: string; value: any }
    uiId: string
  },
) {
  for (const key in state.productCart) {
    for (const product of state.productCart[key]) {
      if (product === null) {
        continue
      }
      if (product.uiId === payload.uiId) {
        product[payload.change.name] = payload.change.value
        return
      }
    }
  }
}

mutations[ProductsMutations.SET_PRODUCT_IN_CART_BOOLEAN_UNIQUE_PROPERTY] =
  function (
    state: ProductState,
    payload: {
      change: { name: string; value: boolean }
      uiId: string
    },
  ) {
    for (const key in state.productCart) {
      for (const product of state.productCart[key]) {
        if (product === null) {
          continue
        }
        if (product.uiId === payload.uiId) {
          product[payload.change.name] = payload.change.value
        } else {
          product[payload.change.name] = !payload.change.value
        }
      }
    }
  }

mutations[ProductsMutations.REMOVE_PRODUCT_FROM_CART] = function (
  state: ProductState,
  productUiId: string,
) {
  for (const key in state.productCart) {
    state.productCart[key] = state.productCart[key].filter(
      (p) => p?.uiId !== productUiId,
    )
  }
}

mutations[ProductsMutations.REMOVE_NULL_PRODUCT_FROM_CART] = function (
  state: ProductState,
  { index, groupName }: { index: number; groupName: string },
) {
  state.productCart[groupName] = state.productCart[groupName].filter(
    (p, i) => i !== index,
  )
}

mutations[ProductsMutations.RESET_PRODUCT_CATALOG] = function (
  state: ProductState,
) {
  state.catalogs = []
  state.products = []
  state.productCart = {}
  state.currentProductGroupSelection = ''
}

mutations[ProductsMutations.RESET_PRODUCTS_CART] = function (
  state: ProductState,
) {
  state.productCart = {}
  state.currentProductGroupSelection = ''
}

export default mutations
