
import { defineComponent } from 'vue'
import Header from '@/components/common/Header.vue'
import NavButton from '@/components/common/NavButton.vue'
import Navigation from '@/components/common/Navigation/Navigation.vue'
import { UserGetters } from '@/store/modules/user/getters'
import { ModulesIdentifiers } from '@/store'
import { UserActions } from '@/store/modules/user/actions'
import { RouteNames } from '@/constants/router'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerActions } from '@/store/modules/partner/actions'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { identifyUser as identifyUserIntercom } from '@/services/intercom'
import { identifyUser as identifyUserImpact } from '@/services/impact'
import { PartnerPrograms } from '@/constants/partner-programs'
import ResetYourPassword from '@/components/account/ResetYourPassword.vue'
import Modal from '@/components/common/Modal.vue'
import { identifyUser } from '@/services/logrocket'
import { Partner } from '@/types/partner'

export default defineComponent({
  name: 'Home',
  components: {
    Header,
    Navigation,
    NavButton,
    Modal,
    ResetYourPassword,
  },
  setup() {
    return {
      PartnerPrograms,
    }
  },
  data() {
    return {
      hasLoadedPartner: false,
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch(
        `${ModulesIdentifiers.USER}/${UserActions.VALIDATE_USER}`,
      )
      await this.$store.dispatch(
        `${ModulesIdentifiers.PARTNER}/${PartnerActions.READ_PARTNER}`,
      )
      await this.$store.dispatch(
        `${ModulesIdentifiers.USER}/${UserActions.GET_CURRENT_USER_INFORMATION}`,
      )

      const currentUser =
        this.$store.getters[`${ModulesIdentifiers.USER}/${UserGetters.USER}`]
      const partner =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
        ]

      // Identify user on Intercom
      identifyUserIntercom(
        currentUser,
        (partner as Partner).friendlyId,
        (partner as Partner).totalNumberOfPlansSold,
        (partner as Partner).totalNumberOfActivePlansSold,
        (partner as Partner).mostRecentPlanSold,
        (partner as Partner).firstPlanSold,
        (partner as Partner).salesPast30,
      )
      // Impact affiliate
      identifyUserImpact((partner as Partner).partnerId)
      // LogRocket
      identifyUser(currentUser, partner)

      this.hasLoadedPartner = true
    } catch (error) {
      await this.$store.dispatch(
        `${ModulesIdentifiers.USER}/${UserActions.LOGOUT}`,
      )
      this.$router.push({ name: RouteNames.LOGIN })
    }
  },
  computed: {
    isAuthed(): boolean {
      const path = `${ModulesIdentifiers.USER}/${UserGetters.AUTHED}`
      return this.$store.getters[path]
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    shouldResetPassword(): boolean {
      const resetPassword =
        this.$store.getters[
          `${ModulesIdentifiers.USER}/${UserGetters.RESET_PASSWORD}`
        ]
      const currentPath = this.$router.currentRoute.value.name
      return resetPassword && currentPath !== RouteNames.HOME_ACCOUNT
    },
  },
})
