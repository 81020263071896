import { ActionContext } from 'vuex'
import {
  Credentials,
  UserState,
  SignupFormFields,
  SignupAndClaimFormFields,
  UserUpdateForm,
} from '@/types/user'
import { State as RootState } from '@/types/vuex'
import axios from 'axios'
import {
  GET_CURRENT_USER,
  GET_PARTNER_PROPERTIES_WITH_ACCESS,
  LOGIN,
  RESET_PASSWORD,
  SIGNUP,
  VALIDATE_USER,
  UPDATE_PASSWORD,
  SIGNUP_AND_CLAIM_USER,
  UPDATE_USER,
  ACCEPT_TERMS_AND_CONDITIONS,
} from '@/services/api'
import { UserMutations } from '@/store/modules/user/mutations'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyMutations } from '@/store/modules/partner-property/mutations'
import { v4 as uuidv4 } from 'uuid'
import { AppMutations } from '@/store/modules/app/mutations'
import { AlertType } from '@/constants/alerts'
import { parseNetworkErrorMessage, pushAlert, root } from '@/utils'
import { AppActions } from '@/store/modules/app/actions'
import { registerRegistration } from '@/services/gtm'
import { identifyUser, trackConversion } from '@/services/impact'
import { PartnerState } from '@/types/partner'

export enum UserActions {
  LOGIN_USER = 'loginUser',
  SIGNUP_USER = 'signupUser',
  SIGNUP_USER_AND_CLAIM_PARTNER = 'signupUserAndClaimPartner',
  LOGOUT = 'logout',
  LOAD_PERMISSIONS = 'loadPermissions',
  GET_PARTNER_PROPERTIES_WITH_ACCESS = 'getPartnerPropertiesWithAccess',
  GET_PARTNER_PROPERTIES_SALES_ACCESS = 'getPartnerPropertiesSalesAccess',
  VALIDATE_USER = 'validateUser',
  RESET_PASSWORD = 'resetPassword',
  GET_CURRENT_USER_INFORMATION = 'getCurrentUserInformation',
  PUT_PASSWORD = 'putPassword',
  UPDATE_USER = 'updateUser',
  ACCEPT_TERMS_AND_CONDITIONS = 'acceptTermsAndConditions',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actions: any = {}

actions[UserActions.LOGIN_USER] = async (
  context: ActionContext<UserState, RootState>,
  credentials: Credentials,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const response = await axios.post(LOGIN, credentials)
    const { email, phone, access_token: token } = response.data
    context.commit(UserMutations.SET_USER_DATA, { email, phone, token })
    localStorage.setItem('token', token)
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[UserActions.SIGNUP_USER] = async (
  context: ActionContext<UserState, RootState>,
  values: SignupFormFields,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const response = await axios.post(SIGNUP, {
      distributor: context.rootState.partner.distributorSlug,
      ...values,
    })
    const { email, phone, access_token: token } = response.data
    context.commit(UserMutations.SET_USER_DATA, { email, phone, token })
    localStorage.setItem('token', token)
    const newStoreFromSignup = {
      name: `${values.business_name} main property`,
      email: values.contact_email,
      phone: values.contact_phone,
      website: values.website,
      repairs: false,
    }
    context.commit(
      `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyMutations.SET_PROPERTIES}`,
      [newStoreFromSignup],
      { root: true },
    )
    const SUCCESS_MESSAGE = 'Congratulations, new account created'
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_ALERT}`,
      { message: SUCCESS_MESSAGE, type: AlertType.SUCCESS },
      { root: true },
    )
    // Call GTM event
    registerRegistration(response.data.user_id, response.data.partner_id)
    // Impact affiliate
    identifyUser(response.data.partner_id)
    trackConversion(response.data.user_id, response.data.partner_id)
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[UserActions.SIGNUP_USER_AND_CLAIM_PARTNER] = async (
  context: ActionContext<UserState, RootState>,
  values: SignupAndClaimFormFields,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const response = await axios.post(SIGNUP_AND_CLAIM_USER, values)
    const { email, phone, access_token: token } = response.data
    context.commit(UserMutations.SET_USER_DATA, { email, phone, token })
    localStorage.setItem('token', token)
    context.commit(PartnerPropertyMutations.SET_PAYPAL_EMAIL, email)
    const SUCCESS_MESSAGE = 'Congratulations, new account created'
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_ALERT}`,
      { message: SUCCESS_MESSAGE, type: AlertType.SUCCESS },
      { root: true },
    )
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[UserActions.LOGOUT] = function (
  context: ActionContext<UserState, RootState>,
): void {
  localStorage.removeItem('token')
  context.commit(UserMutations.SET_LOGOUT)
}

actions[UserActions.GET_PARTNER_PROPERTIES_WITH_ACCESS] = async function (
  context: ActionContext<UserState, RootState>,
  payload: { scope: string; value: string },
): Promise<void> {
  const response = await axios.get(
    `${GET_PARTNER_PROPERTIES_WITH_ACCESS}${payload.scope}/${payload.value}/`,
  )
  context.commit(UserMutations.SET_PARTNER_PROPERTIES_WITH_ACCESS, {
    rawPartnerProperties: response.data,
    ...payload,
  })
}

actions[UserActions.VALIDATE_USER] = async (
  context: ActionContext<UserState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  if (!context.state.authed) {
    try {
      const response = await axios.get(VALIDATE_USER)
      const { email, phone } = response.data
      const token = localStorage.getItem('token')
      context.commit(UserMutations.SET_USER_DATA, { email, phone, token })
    } catch (error) {
      localStorage.removeItem('token')
      context.commit(UserMutations.SET_LOGOUT)
      return Promise.reject((error as any).response.data)
    }
  } else {
    return Promise.resolve(true)
  }
}

actions[UserActions.RESET_PASSWORD] = async (
  context: ActionContext<UserState, RootState>,
  phone: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const operationId = uuidv4()
  try {
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_LOADING_OPERATION}`,
      {
        id: operationId,
      },
      { root: true },
    )
    const response = await axios.post(RESET_PASSWORD, { phone })
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
      {
        id: operationId,
      },
      { root: true },
    )
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_ALERT}`,
      {
        message: 'Password was successfully reset.',
        type: AlertType.SUCCESS,
      },
      { root: true },
    )
    for (const index in response.data.data?.warnings || []) {
      context.commit(
        `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_ALERT}`,
        {
          message: response.data.data.warnings[index],
          type: AlertType.WARNING,
        },
        { root: true },
      )
    }
  } catch (e) {
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
      {
        id: operationId,
      },
      { root: true },
    )
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_ALERT}`,
      {
        message: parseNetworkErrorMessage(e, 'Error resetting password'),
        type: AlertType.ERROR,
      },
      { root: true },
    )
    // Trowing error in order to allow component perform extra error handling
    throw parseNetworkErrorMessage(e)
  }
}

actions[UserActions.GET_CURRENT_USER_INFORMATION] = async (
  context: ActionContext<UserState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const operationId = uuidv4()
  try {
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_LOADING_OPERATION}`,
      {
        id: operationId,
      },
      { root: true },
    )
    const response = await axios.get(GET_CURRENT_USER)
    const { data } = response.data
    const { partner_property_permissions } = data
    context.commit(UserMutations.SET_USER_DATA, data)
    context.commit(
      UserMutations.SET_PARTNER_PROPERTY_PERMISSIONS,
      partner_property_permissions,
    )
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
      {
        id: operationId,
      },
      { root: true },
    )
  } catch (e) {
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
      {
        id: operationId,
      },
      { root: true },
    )
  }
}

actions[UserActions.PUT_PASSWORD] = async (
  context: ActionContext<UserState, RootState>,
  password: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const action = `${ModulesIdentifiers.APP}/${AppActions.TOGGLE_LOADING_OPERATION}`
  const operation = { id: uuidv4(), show: false }
  const root = { root: true }
  try {
    context.dispatch(action, { ...operation, show: true }, root)
    await axios.put(UPDATE_PASSWORD, { password })
    context.dispatch(action, operation, root)
    const MESSAGE = 'Your password was updated'
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_ALERT}`,
      { message: MESSAGE, type: AlertType.SUCCESS },
      { root: true },
    )
  } catch (err) {
    context.dispatch(action, operation, root)
    throw parseNetworkErrorMessage(err)
  }
}

actions[UserActions.UPDATE_USER] = async (
  context: ActionContext<UserState, RootState>,
  values: UserUpdateForm,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const action = `${ModulesIdentifiers.APP}/${AppActions.TOGGLE_LOADING_OPERATION}`
  const operation = { id: uuidv4(), show: false }
  try {
    context.dispatch(action, { ...operation, show: true }, root)
    const response = await axios.patch(UPDATE_USER, values)
    const { data } = response.data
    context.dispatch(action, operation, root)
    context.commit(UserMutations.UPDATE_USER, data)
    const message = 'Profile updated'
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_ALERT}`,
      { message, type: AlertType.SUCCESS },
      root,
    )
  } catch (err) {
    context.dispatch(action, operation, root)
    throw parseNetworkErrorMessage(err)
  }
}

actions[UserActions.ACCEPT_TERMS_AND_CONDITIONS] = async (
  context: ActionContext<PartnerState, RootState>,
): Promise<void> => {
  try {
    await axios.post(ACCEPT_TERMS_AND_CONDITIONS)
    await context.dispatch(UserActions.GET_CURRENT_USER_INFORMATION)
    const SUCCESS_MESSAGE = 'Terms and Conditions accepted'
    pushAlert(context, SUCCESS_MESSAGE, AlertType.SUCCESS)
  } catch (err) {
    const error = parseNetworkErrorMessage(err)
    pushAlert(context, error, AlertType.ERROR)
    throw error
  }
}

export default actions
