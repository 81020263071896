
import { defineComponent } from 'vue'
import { RouteNames, RoutePath } from '@/constants/router'
import isEmpty from 'lodash/isEmpty'
import QuickActions from '@/components/dashboard/QuickActions.vue'
import SalesOverview from '@/components/dashboard/SalesOverview.vue'
import { showChat } from '@/services/intercom'
import { PartnerPrograms } from '@/constants/partner-programs'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'
import { ReportActions } from '@/store/modules/report/actions'
import { SalesOverview as SalesOverviewType } from '@/types/report'
import { ReportGetters } from '@/store/modules/report/getters'
import PayoutCompensationBody from '@/components/common/compensation/PayoutCompensationBody.vue'
import { UserGetters } from '@/store/modules/user/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'

export default defineComponent({
  name: 'Dashboard',
  components: {
    QuickActions,
    SalesOverview,
    PayoutCompensationBody,
  },
  setup() {
    return {
      RoutePath,
      PartnerPrograms,
    }
  },
  mounted() {
    if (isEmpty(this.salesOverview)) {
      this.$store.dispatch(
        `${ModulesIdentifiers.REPORT}/${ReportActions.SALES_OVERVIEW}`,
      )
    }
  },
  computed: {
    isHomePage(): boolean {
      return this.$router.currentRoute.value.name == RouteNames.HOME_MENU
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    salesOverview(): SalesOverviewType[] {
      const key = `${ModulesIdentifiers.REPORT}/${ReportGetters.SALES_OVERVIEW}`
      return this.$store.getters[key]
    },
    canViewCompensationAmount(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_ADMIN_PAYOUT_AMOUNT,
        PermissionValues.VIEW,
      )
    },
  },
  methods: {
    showIntercomChat(): void {
      showChat()
    },
  },
})
