import { ActionContext } from 'vuex'
import { ProductState } from '@/types/product'
import { State as RootState } from '@/types/vuex'
import axios from 'axios'
import {
  GET_CATALOG_PRODUCTS,
  GET_INTERVALS,
  GET_PARTNER_CATALOGS,
} from '@/services/api'
import { ProductsMutations } from '@/store/modules/products/mutations'

export enum ProductsActions {
  LOAD_CATALOG_PRODUCTS = 'loadCatalogProducts',
  LOAD_PARTNER_CATALOGS = 'loadPartnerCatalogs',
  LOAD_INTERVALS = 'loadIntervals',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actions: any = {}

actions[ProductsActions.LOAD_PARTNER_CATALOGS] = async function (
  context: ActionContext<ProductState, RootState>,
): Promise<void> {
  try {
    const path = GET_PARTNER_CATALOGS.replace(
      '{0}',
      context.rootState.partner.partnerId.toString(),
    )
    const result = await axios.get(path)
    const catalogs = result?.data?.data || []
    context.commit(ProductsMutations.SET_CATALOGS, catalogs)
  } catch (err) {
    // TODO: Notify user on failure
  }
}

actions[ProductsActions.LOAD_CATALOG_PRODUCTS] = async function (
  context: ActionContext<ProductState, RootState>,
): Promise<any> {
  try {
    const requestCollection = context.state.catalogs.map((catalogId) => {
      const path = GET_CATALOG_PRODUCTS.replace(
        '{0}',
        context.rootState.partner.partnerId.toString(),
      ).replace('{1}', catalogId)
      return axios.get(path)
    })
    const productCollectionsResponse = await Promise.all(requestCollection)
    productCollectionsResponse.forEach((productCollection) => {
      if (productCollection?.data?.data) {
        context.commit(
          ProductsMutations.SET_PRODUCTS,
          productCollection.data.data,
        )
        context.commit(
          ProductsMutations.ADD_PRODUCTS_TO_PRODUCT_GROUPS,
          productCollection.data.data,
        )
      }
    })
  } catch (err) {
    // TODO: Notify user on failure
  }
}

actions[ProductsActions.LOAD_INTERVALS] = async function (
  context: ActionContext<ProductState, RootState>,
): Promise<any> {
  try {
    const result = await axios.get(GET_INTERVALS)
    const intervals = result?.data?.data || []
    context.commit(
      ProductsMutations.SET_INTERVALS_MONTHLY_CONVERSION_RATIOS,
      intervals,
    )
  } catch (err) {
    // TODO: Notify user on failure
  }
}

export default actions
