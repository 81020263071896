import {
  SalesState,
  SalesSubState,
  SalesSubStateFixedAmount,
  SalesSubStateByTeam,
} from '@/types/sales'

export enum SalesMutations {
  SET_SALES_FILTER_TO_TOTAL = 'setSalesFilterToTotal',
  SET_SALES_FILTER_TO_LAST_7_DAYS = 'setSalesFilterToLast7days',
  SET_SALES_FILTER_TO_LAST_30_DAYS = 'setSalesFilterToLast30days',
  SET_SALES_FILTER_TO_LAST_MONTH = 'setSalesFilterToLastMonth',
  SET_SALES_FILTER_TO_CUSTOM_MONTH = 'setSalesFilterToCustomMonth',
  SET_SALES_FILTER_TO_LAST_TODAY = 'setSalesFilterToToday',
  SET_SALES_FILTER_TO_MONTH_BY_TEAM = 'setSalesFilterMonthByTeam',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mutations: any = {}

mutations[SalesMutations.SET_SALES_FILTER_TO_LAST_7_DAYS] = (
  state: SalesState,
  newData: SalesSubState,
): void => {
  state.last7days = { ...state.last7days, ...newData }
}

mutations[SalesMutations.SET_SALES_FILTER_TO_LAST_30_DAYS] = (
  state: SalesState,
  newData: SalesSubState,
): void => {
  state.last30days = { ...state.last30days, ...newData }
}

mutations[SalesMutations.SET_SALES_FILTER_TO_LAST_MONTH] = (
  state: SalesState,
  newData: SalesSubState,
): void => {
  state.lastmonth = { ...state.lastmonth, ...newData }
}

mutations[SalesMutations.SET_SALES_FILTER_TO_CUSTOM_MONTH] = (
  state: SalesState,
  newData: SalesSubState,
): void => {
  state.customMonth = { ...newData }
}

mutations[SalesMutations.SET_SALES_FILTER_TO_LAST_TODAY] = (
  state: SalesState,
  newData: SalesSubState,
): void => {
  state.today = { ...state.today, ...newData }
}

mutations[SalesMutations.SET_SALES_FILTER_TO_TOTAL] = (
  state: SalesState,
  newData: SalesSubStateFixedAmount,
): void => {
  state.total = { ...state.total, ...newData }
}

mutations[SalesMutations.SET_SALES_FILTER_TO_MONTH_BY_TEAM] = (
  state: SalesState,
  newData: SalesSubStateByTeam,
): void => {
  state.customMonthByTeam = { ...state.customMonthByTeam, ...newData }
}

export default mutations
